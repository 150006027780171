<template>
    <div
      ref="activeCallContainer"
      :class="classes.activeCallContainer"
    >
      <div class="remote-streams">
        <RemoteMedia :activeCall="activeCall" :configs="configs" @on-action="parseOnAction" :platform="platform"/>
      </div>
      <div class="local-container" :style="styles.localContainer">
        <LocalMedia :activeCall="activeCall" @on-action="parseOnAction" :platform="platform"/>
        <div class="local-features">
          <AgentFeatures :activeCall="activeCall"/>
        </div>
      </div>
    </div>
</template>

<script>
import './../style.desktop.css';
export default {
  props: ['activeCall','platform'],
  components: {
    AgentFeatures: () => import('./../Features'),
    LocalMedia: () => import('./../LocalMedia'),
    RemoteMedia: () => import('./../RemoteMedia')
  },
  data(){
    return {
      configs: {
        minimized: false,
        fullscreen: false
      },
      classes: {
        activeCallContainer: "active-call"
      },
      styles: {
        localContainer: 'display: block;'
      }
    };
  },

  methods: {
    on_minimize(){
      this.configs.minimized = true;
      this.classes.activeCallContainer = "active-call-minimized";
      this.styles.localContainer = 'display: none;'
    },
    on_maximize(){
      this.configs.minimized = false;
      this.classes.activeCallContainer = "active-call";
      this.styles.localContainer = 'display: block;'
      this.$refs['activeCallContainer'].style = null;
    },

    on_fullscreen(data){
      let {state} = data;

      if(this.configs.fullscreen === false && state === false){
        return;
      }

      this.configs.fullscreen = state;
      this.$callstr.utils().fullscreen(state, this.$refs['activeCallContainer']);
    },

    dragElement(elmnt) {
      var _this = this;
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        if(!_this.configs.minimized){
          return;
        }
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        if(!_this.configs.minimized){
          return;
        }
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";

        let rect = elmnt.getBoundingClientRect();

        if(parseInt(elmnt.style.top.replace('px')) < 0){
          elmnt.style.top = '0px';
        }
        if(parseInt(elmnt.style.left.replace('px')) < 0){
          elmnt.style.left = '0px';
        }

        if(rect.bottom > window.innerHeight){
          elmnt.style.top = (window.innerHeight - elmnt.clientHeight) + "px";
        }
        if(rect.right > window.innerWidth){
          elmnt.style.left = (window.innerWidth - elmnt.clientWidth) + "px";
        }
      }

      function closeDragElement() {
        if(!_this.configs.minimized){
          return;
        }
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },

    parseOnAction({event, data}){
      this['on_'+event](data);
    }
  },

  mounted(){
    this.dragElement(this.$refs['activeCallContainer']);
  }
};
</script>
